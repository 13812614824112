class HeadingAdjuster {
  constructor(maxLevel) {
    this.maxLevel = maxLevel || 6; // Default to 6 if no parameter is provided
    this.headings = document.querySelectorAll("h1, h2, h3, h4, h5, h6");
    this.currentLevel = 1; // Start with h1
    this.adjustHeadings();
  }

  adjustHeadings() {
    this.headings.forEach((heading) => {
      const headingLevel = parseInt(heading.tagName.charAt(1));

      if (headingLevel === this.currentLevel) {
        // Heading matches current level, so keep it
        this.incrementLevel();
      } else if (headingLevel > this.currentLevel) {
        // Heading is at a higher level than current, so adjust it down
        this.convertHeading(heading, this.currentLevel);
        this.incrementLevel();
      } else {
        // Heading is at a lower level than current, so convert it up
        this.convertHeading(heading, this.currentLevel);
      }
    });
  }

  incrementLevel() {
    // Increment the current level without exceeding maxLevel
    if (this.currentLevel < this.maxLevel) {
      this.currentLevel++;
    }
  }

  convertHeading(heading, newLevel) {
    const newHeading = document.createElement(`h${newLevel}`);
    newHeading.innerHTML = heading.innerHTML;

    // Copy all attributes from the old heading to the new heading
    Array.from(heading.attributes).forEach((attr) => {
      newHeading.setAttribute(attr.name, attr.value);
    });

    heading.parentNode.replaceChild(newHeading, heading);
  }
}

export default HeadingAdjuster;

// // Usage example with maxLevel 3
// document.addEventListener("DOMContentLoaded", () => {
//   new HeadingAdjuster(3);
// });
