// Our modules / classes
// import Team from "./modules/Team";
import HeadingAdjuster from "./modules/HeadingAdjuster";

// // Instantiate a new object using our modules/classes
// const mobileMenu = new MobileMenu();
// const heroSlider = new HeroSlider();
// const googleMap = new GoogleMap();
// const search = new Search();
// const myNotes = new MyNotes();
// const like = new Like();

// const team = new Team();
const headingAdjuster = new HeadingAdjuster(2);
